import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  TagInput,
} from "rsuite";
import { Edit } from "@rsuite/icons";
import TrashIcon from "@rsuite/icons/Trash";

import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ExrciceAtom } from "../../Atoms/exercice.atom";

function AddEdit({
  _setmodel,
  error,
  model = { items: [] },
  clients,
  fetchClients,
  agencies,
  taxes,
}) {
  const exercices = useRecoilValue(ExrciceAtom);

  const [item, setitem] = useState({
    designation: "",
    qty: 1,
    price: 0,
    isTaxed: true,
  });
  const reset = () => {
    setitem({ designation: "", qty: 1, price: 0, isTaxed: true , stampDuty:true});
  };
  useEffect(() => console.log(exercices), []);
  return (
    <>
      <label> Date </label>
      <Input
        type="date"
        onChange={(date) => {
          console.log(date);
          _setmodel((prev) => {
            return { ...prev, date };
          });
        }}
        value={
          typeof model.date == "string"
            ? model.date.substring(0, 10)
            : model.date
        }
      />
      <label>Client:</label>
      <SelectPicker
        onSearch={(q) => fetchClients(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          clients.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.clientId}
        onSelect={(clientId) => {
          _setmodel((prev) => {
            return { ...prev, clientId };
          });
        }}
        defaultValue={model.clientId}
      />
      <label>Société</label>
      <SelectPicker
        data={[{ label: "Tout", value: 0 }].concat(
          agencies.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.agencyId}
        onSelect={(agencyId) => {
          _setmodel((prev) => {
            return { ...prev, agencyId };
          });
        }}
      />
      <label>Exercice</label>
      <SelectPicker
        data={[{ label: "Tout", value: 0 }].concat(
          exercices
            .filter((ex) => ex.agencyId == model.agencyId)
            .map((c) => {
              return { label: c.year, value: c.id };
            })
        )}
        block
        noSearch
        value={model.exerciceId}
        onSelect={(exerciceId) => {
          _setmodel((prev) => {
            return { ...prev, exerciceId };
          });
        }}
      />
       <label>
          <Checkbox
            style={{
              border: "1px solid #aaa",
              borderRadius: "5px",
              width: "36px",
              height: "36px",
              padding: 0,
              backgroundColor: "#fff",
              marginRight: "10px",
            }}
            value={model.stampDuty}
            
            onChange={(stampDuty) => {
              _setmodel((prev) => {
                return { ...prev, stampDuty: !prev.stampDuty };
              });
            }}
            checked={model.stampDuty}
            defaultValue={true}
          />
         Droit De Timbre :
        </label>
        <br></br>
      <label>TAX</label>
      <SelectPicker
        data={[{ label: "Tout", value: 0 }].concat(
          taxes.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.taxId}
        onSelect={(taxId) => {
          _setmodel((prev) => {
            return { ...prev, taxId };
          });
        }}
      />
      <label>Liste Des Produits / Services :</label>
      <div style={{ background: "rgb(0,169,141,0.2)", padding: "5px" }}>
        <label>
          <Checkbox
            style={{
              border: "1px solid #aaa",
              borderRadius: "5px",
              width: "36px",
              height: "36px",
              padding: 0,
              backgroundColor: "#fff",
              marginRight: "10px",
            }}
            onChange={(isTaxed) => {
              setitem((prev) => {
                return { ...prev, isTaxed: !prev.isTaxed };
              });
            }}
            value={item.isTaxed}
            checked={item.isTaxed}
            defaultValue={true}
          />
          Tax Appilcable :
        </label>
        <br></br>
        <label>Désignation:</label>

        <Input
          as="textarea"
          onChange={(designation) => {
            setitem((prev) => {
              return { ...prev, designation };
            });
          }}
          value={item.designation}
        />
        <label>Quantité:</label>

        <Input
          type="number"
          onChange={(qty) => {
            setitem((prev) => {
              return { ...prev, qty: parseInt(qty) };
            });
          }}
          value={item.qty}
        />
        <label>Prix:</label>

        <Input
          step="0.1"
          type="number"
          onChange={(amount) => {
            setitem((prev) => {
              return { ...prev, amount: parseFloat(amount) };
            });
          }}
          value={item.amount}
        />
        {/* <label>Commission:</label>

        <Input
          step="0.1"
          type="number"
          onChange={(commission) => {
            setitem((prev) => {
              return { ...prev, commission: parseFloat(commission) };
            });
          }}
          value={item.amount}
        /> */}
        <div style={{ textAlign: "right", margin: "5px" }}>
          <Button
            color="green"
            onClick={() => {
              _setmodel((prev) => ({
                ...prev,
                items: item.index
                  ? [
                      ...prev.items.map((el) => {
                        if (el.index != item.index) return el;
                        else return item;
                      }),
                    ]
                  : [
                      ...prev.items,
                      {
                        ...item,
                        index: prev.items.length
                          ? prev.items[prev.items.length - 1].index + 1
                          : 1,
                      },
                    ],
              }));
              reset();
            }}
          >
            {item.index ? "modifier" : "ajouter"}
          </Button>
        </div>
      </div>
      <table>
        <thead>
          <tr style={{ background: "#454599", color: "#fff" }}>
            <td>Désignation</td> <td>Qté</td> <td>Prix</td> <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {model.items.map((el) => (
            <tr style={{ color: "#454599" }}>
              <td>{el.designation}</td> <td>{el.qty}</td> <td>{el.amount}</td>
              <td>
                <IconButton
                  appearance="subtle"
                  onClick={() => setitem(el)}
                  icon={<Edit />}
                  circle
                />
                <Divider vertical />
                <IconButton
                  appearance="subtle"
                  onClick={() =>
                    _setmodel((prev) => ({
                      ...prev,
                      items: prev.items.filter((el1) => el1.index != el.index),
                    }))
                  }
                  icon={<TrashIcon />}
                  circle
                />{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
