import {
  IconButton,
  Input,
  Message,
  SelectPicker,
  TagInput,
  TagPicker,
  Uploader,
} from "rsuite";
import Responsive from "../../Components/Responsive";
import ImageIcon from "@rsuite/icons/Image";
import { useState } from "react";

function AddEdit({ _setmodel, error, model }) {
  const [logo, setlogo] = useState(null);
  const [file, setfile] = useState(null);
  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }
  return (
    <>
      <h5>Les informations de l'agence pour la facture:</h5>
      <div style={{ width: "100px", margin: "15px" }}>
        <Uploader
          // listType="picture"
          autoUpload={false}
          action="#"
          draggable
          fileListVisible={false}
          // onUpload={}
          onChange={(files) => {
            console.log(files);
            setfile(files[files.length - 1].blobFile);
            previewFile(files[files.length - 1].blobFile, (value) => {
              _setmodel((prev) => ({ ...prev, logo: value }));
              setlogo(value);
            });
          }}
        >
          <div
            style={{
              background: "rgba(200,200,200,0.1)",
              width: "100px",
              height: "100px",
              padding: "0",
            }}
          >
            {logo ? (
              <img src={logo} width="100" height="100" alt="" />
            ) : model.logo ? (
              <img
                width="100"
                height="100"
                src={
                  model.logo
                  //   "http://localhost:3003/images/sasasd.png"
                }
                alt=""
              />
            ) : (
              <>
                {" "}
                <div style={{ fontSize: "40px" }}>
                  {" "}
                  <ImageIcon color="#3598ff"></ImageIcon>
                </div>
                <h4 style={{ color: "#bbb", fontWeight: "400" }}>
                  LOGO
                  {/* pour les télécharger */}
                </h4>
              </>
            )}
          </div>
        </Uploader>
      </div>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Nom:</label>
        <Input
          value={model.name}
          onChange={(name) => {
            _setmodel((prev) => {
              console.log(prev);
              return { ...prev, name };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Matricule Fiscale :</label>
        <Input
          value={model.taxCode}
          onChange={(taxCode) => {
            _setmodel((prev) => {
              return { ...prev, taxCode };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Addresse :</label>
        <Input
          value={model.address}
          onChange={(address) => {
            _setmodel((prev) => {
              return { ...prev, address };
            });
          }}
        />
      </Responsive>

      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Télephones</label>
        <TagInput
          type="number"
          block
          size="md"
          // placeholder="numéros des télephones"
          value={model.phones ? model.phones.split(",") : []}
          onChange={(phones) => {
            let m = { ...model };
            m.phones = phones.join(",");
            _setmodel(m);
          }}
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Fax:</label>
        <TagInput
          type="number"
          block
          size="md"
          //   placeholder="emails "
          value={model.faxes ? model.faxes.split(",") : []}
          onChange={(faxes) => {
            let m = { ...model };

            m.faxes = faxes.join(",");
            _setmodel(m);
          }}
        />{" "}
      </Responsive>

      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>emails:</label>
        <TagInput
          type="number"
          block
          size="md"
          //   placeholder="emails "
          value={model.emails ? model.emails.split(",") : []}
          onChange={(emails) => {
            let m = { ...model };

            m.emails = emails.join(",");
            _setmodel(m);
          }}
        />{" "}
      </Responsive>
      {/* <div style={{ textAlign: "right" }}>
        <IconButton
          appearance="primary"
          onClick={save}
          color="blue"
          icon={<CreditCardPlusIcon />}
        >
          {model.id ? "modifier" : "Ajouter"}
        </IconButton>
        </div> */}
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>RIB:</label>
        <Input
          block
          size="md"
          //   placeholder="emails "
          value={model.rib}
          onChange={(rib) => {
            _setmodel((prev) => ({ ...prev, rib }));
          }}
        />{" "}
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>SWIFT:</label>
        <Input
          block
          size="md"
          //   placeholder="emails "
          value={model.swift}
          onChange={(swift) => {
            _setmodel((prev) => ({ ...prev, swift }));
          }}
        />{" "}
      </Responsive>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
