import React, { useEffect, useState } from "react";
import { Checkbox, Modal, SelectPicker } from "rsuite";
import { read, utils, writeFile } from "xlsx";
import AddEdit from "./AddEdit.component";
import { useRecoilState, useRecoilValue } from "recoil";
import { ExrciceAtom } from "../../Atoms/exercice.atom";
import { agencyAtoms } from "../../Atoms/agencies.atom";
import { TaxAtom } from "../../Atoms/taxes.atom";
import { PreferenceAtom } from "../../Atoms/preference.atom";
import { APi } from "../../Api";
import Swal from "sweetalert2";
import autoTable from "jspdf-autotable";
import format_number from "../../Helpers/number_formatter";
import Final_Amount_Converter from "../../Helpers/amountToString";
import moment from "moment";
import { ENDPOINTS } from "../../Api/enpoints";
import jsPDF from "jspdf";

export default function InvoiceGenerator() {
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [dataRefund, setdataRefund] = useState([]);
  const [dataAdm, setdataAdm] = useState([]);
  const [checkeds, setcheckeds] = useState([]);
  const [sheetName, setSheetName] = useState("aucun fichier selectionné");
  const [clients, setclients] = useState([]);
  const [clientsFromDb, setclientsFromDb] = useState([]);
  const [agencies, setagencies] = useRecoilState(agencyAtoms);
  const [taxes, settaxes] = useRecoilState(TaxAtom);
  const [pref, setpref] = useRecoilState(PreferenceAtom);
  const [open, setopen] = useState(false);
  const [active, setactive] = useState(0);
  const [selectedclient, setselectedclient] = useState(null);
  const [invoiceModel, setinvoiceModel] = useState({
    items: [],
    clientId: 0,
    agencyId: 0,
    date: new Date(),
    stampDuty: true,
  });
  const fetchClients = (q, forFilter = true) => {
    if (q && q.length > 2) {
      APi.createAPIEndpoint(
        APi.ENDPOINTS.Client,
        { q, take: 10000 },
        "/autocomplete"
      )
        .customGet()
        .then((res) => setclientsFromDb(res.data));
    } else
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, {}, "/getAll")
        .customGet()
        .then((res) => setclientsFromDb(res.data));
  };
  useEffect(() => {
    fetchClients();
  }, []);
  const exercices = useRecoilValue(ExrciceAtom);
  const handleClose = () => {
    setopen(false);
    setinvoiceModel({
      items: [],
      clientId: 0,
      agencyId: 0,
      date: new Date(),
      stampDuty: true,
    });
  };
  const readfile2 = ($event) => {
    const files = $event.target.files;
    let _rows = [];
    if (files.length) {
      debugger;
      const file = files[0];
      setSheetName(file.name.split(".")[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, {
          cellDates: true,
          dateNF: "YYYY-MM-DD",
          type: "array",
        });
        const sheets = wb.SheetNames;
        console.log(sheets);

        if (sheets.length) {
          let l = [];
          console.log(
            Object.keys(
              utils.sheet_to_json(wb.Sheets[sheets[0]], {
                blankrows: true,

                /** Default value for null/undefined values */

                /** if true, skip hidden rows and columns */
                skipHidden: false,
              })[0]
            )
          );
          let _data = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            blankrows: true,

            /** Default value for null/undefined values */

            /** if true, skip hidden rows and columns */
            skipHidden: false,
          });
          _data = _data
            .filter(
              (el) =>
                !(
                  el["Nom Agence"].toUpperCase().includes("UMRAH") ||
                  el["Nom Agence"].toUpperCase().includes("KOUNOUZ")
                )
            )
            .map((l, i) => {
              return {
                client: l["Nom Agence"],
                designation:
                  "Billet /" +
                  l["Nom Client"].replace("-(E-Power)", "") +
                  "#" +
                  l["Numero ticket"] +
                  "#" +
                  l["Statut"],
                // "Billet n°" + l["Numero ticket"] + " / " + l["Nom Client"],
                ttc:
                  l["Prix vente"] || l["Montant Remboursé"] - l["Commissions"],
                refundAmount: l["Montant Remboursé"],
                amount:
                  l["Prix billet"] || l["Montant Remboursé"] - l["Commissions"],
                status: l["Statut"],
                commission_r: l["Commissions"],

                commission:
                  !l["Commissions"] || l["Statut"] != "TKTT"
                    ? 0
                    : l["Prix billet"] <= 1500
                    ? 10
                    : l["Prix billet"] > 2500
                    ? 25
                    : 20,
                id: `${l["Numero ticket"]}`,
                qty: 1,
              };
            });
          let _clients = new Set(_data.map((el) => el.client));
          setclients(Array.from(_clients).map((c) => ({ label: c, value: c })));
          setdata(
            _data
              .filter(
                (el) =>
                  el.status == "TKTT" ||
                  el.status == "EMDS" ||
                  el.status == "EMDA"
              )
              .map((l) => {
                let _l = { ...l };
                delete _l.refundAmount;
                return _l;
              })
          );
          setfilteredData(
            _data
              .filter(
                (el) =>
                  el.status == "TKTT" ||
                  el.status == "EMDS" ||
                  el.status == "EMDA"
              )
              .map((l) => {
                let _l = { ...l };
                delete _l.refundAmount;
                return _l;
              })
          );

          setdataRefund(_data.filter((el) => el.status == "RFND"));
          console.log(_data.filter((el) => el.status == "RFND"));
          setdataAdm(
            _data.filter((el) => el.status == "ADMA" || el.status == "ADM")
          );
          console.log(
            _data.filter((el) => el.status == "ADMA" || el.status == "ADM")
          );
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  async function downloadInvoice(inv) {
    const doc = new jsPDF();
    let agency = agencies.find((el) => el.id == inv.agencyId);
    let ex = exercices.find((ex) => ex.id == inv.exerciceId);
    var imgData = agency.logo;
    console.log(agency);

    let c = await APi.createAPIEndpoint(ENDPOINTS.Client).fetchById(
      inv.clientId
    );
    let client = c.data;
    console.log(client);
    let numb = inv.number;
    console.log(client);
    doc.addImage(imgData, "JPEG", 15, 26, 36, 25);
    // .then((res) => (client = res.data));
    autoTable(doc, {
      body: [
        [
          {
            content: "",
            styles: {
              halign: "left",
              fontSize: 14,
              textColor: "#232323",
            },
          },
          {
            content: inv.isAvoir ? "Avoir" : "Facture",
            styles: {
              halign: "right",
              fontSize: 14,
              textColor: "#232323",
              fontWeight: "bold",
            },
          },
        ],
      ],
      theme: "plain",
      styles: {
        fillColor: "#eee",
      },
    });

    autoTable(doc, {
      body: [
        [
          {
            content:
              "\n" +
              agency.name +
              "\n" +
              "\n" +
              agency.address +
              (agency.emails ? "\n" + agency.emails : "") +
              (agency.phones
                ? agency.phones.split(",").map((p) => "\nTél: " + p)
                : "") +
              "\nMF : " +
              agency.taxCode,
            styles: {
              halign: "left",
              fontSize: 10,
              textColor: "#27509a",
              //  fontStyle: "italic",
              fontWeight: "bold",
              fontStyle: "Amiri",
              cellPadding: { top: 12, left: 2, right: 4 },
            },
          },

          {
            content:
              "Client : \n" +
              client.name +
              "\n" +
              "\n" +
              client.address +
              "\n MF. / CIN : " +
              client.taxCode,
            styles: {
              halign: "left",
              fontSize: 10,
              textColor: "#27509a",
              fontWeight: "bold",
              fontStyle: "Amiri",
              cellPadding: { top: 5, left: 2 },
              lineColor: "#eeeeee",
              lineWidth: 0.3,
              overflow: "linebreak",
            },
          },
        ],
      ],
      theme: "plain",
      margin: { top: 5 },
      columnStyles: {
        0: {
          columnWidth: doc.internal.pageSize.getWidth() / 2 - 20,
        },
        1: {
          columnWidth: doc.internal.pageSize.getWidth() / 2 - 10,
        },
      },
    });
    autoTable(doc, {
      body: [
        [
          {
            content:
              "Date: " +
              moment(inv.date).format("DD-MM-YYYY") +
              "\nN." +
              (!inv.isAvoir ? "Facture" : "Avoir") +
              ": " +
              (ex ? ex.year : "") +
              " / " +
              ("0000" + numb).split("").slice(-4).join(""),
            styles: {
              halign: "left",
              fontSize: 10,
              textColor: "#232323",
              fontStyle: "bold",
              cellPadding: { left: 10 },
            },
          },
        ],
      ],
      tableWidth: doc.internal.pageSize.getWidth() - 30,
      theme: "plain",
    });

    autoTable(doc, {
      head: [
        [
          "Désignation",
          {
            content: "Qté",
            styles: {
              halign: "right",
            },
          },

          {
            content: "Prix Unitaire TTC",
            styles: {
              halign: "right",
            },
          },
          {
            content: "Prix Total TTC",
            styles: {
              halign: "right",
            },
          },
        ],
      ],
      foot: [
        [
          "Montant Total TTC",
          "",
          "",
          {
            content: format_number(
              inv.items.reduce((a, b) => {
                return a + b.amount * b.qty;
              }, 0)
            ),
            styles: {
              halign: "right",
            },
          },
        ],
      ],
      body: inv.items.map((a) => {
        return [
          a.designation.split("#")[0],
          // el.unitPrice,
          {
            content: a.qty,
            styles: {
              halign: "right",
            },
          },
          {
            content: format_number(a.amount),
            styles: {
              halign: "right",
            },
          },
          {
            content: format_number(a.qty * a.amount),
            styles: {
              halign: "right",
            },
          },
        ];
      }, []),
      theme: "striped",
      headStyles: {
        textColor: "#eee",
        fillColor: "#343a40",
      },
      footStyles: {
        fillColor: "#eee",
        textColor: "#343a40",
      },
    });

    autoTable(doc, {
      body: [
        [
          {
            content: "Arrêtée la présente facture à la somme de :",
            styles: {
              halign: "left",
              textColor: "#27509a",
              cellPadding: { left: 4 },
              fontStyle: "italic",
            },
          },
          {
            content: "Montant Total HT:",
            styles: {
              halign: "left",
            },
          },
          {
            content: format_number(
              inv.items.reduce((a, b) => a + b.amount * b.qty - b.commission, 0)
            ),
            styles: {
              halign: "right",
            },
          },
        ],

        [
          {
            content: Final_Amount_Converter(
              inv.items.reduce((a, b) => a + b.amount * b.qty, 0) +
                (inv.stampDuty ? (ex && ex.year < 2023 ? 0.6 : 1) : 0)
            ),
            styles: {
              halign: "left",
              fontWeight: "bold",
              fontStyle: "italic",
              cellPadding: { right: 7, left: 4 },
              overflow: "linebreak",
              cellWidth: doc.internal.pageSize.getWidth() / 2 + 10,
            },
          },
          {
            content: "Base TVA",
            styles: {
              halign: "left",
            },
          },
          {
            content: format_number(
              inv.items.reduce(
                (a, b) =>
                  a +
                  b.commission *
                    (1 -
                      (taxes.find((el) => el.id == inv.taxId)
                        ? taxes.find((el) => el.id == inv.taxId).rate / 100
                        : 0.19)),
                0
              )
            ),
            styles: {
              halign: "right",
            },
          },
        ],
        [
          {
            content: "",
            styles: {
              halign: "left",
            },
          },

          {
            content: "TVA",
            styles: {
              halign: "left",
            },
          },
          {
            content:
              (taxes.find((el) => el.id == inv.taxId)
                ? taxes.find((el) => el.id == inv.taxId).rate
                : "19") +
              "%              " +
              format_number(
                inv.items.reduce(
                  (a, b) =>
                    a +
                    b.commission *
                      (taxes.find((el) => el.id == inv.taxId)
                        ? taxes.find((el) => el.id == inv.taxId).rate / 100
                        : 0.19),
                  0
                )
              ),
            styles: {
              halign: "right",
            },
          },
        ],
        [
          {
            content: "",
            styles: {
              halign: "left",
            },
          },
          {
            content: "Droit de Timbre",
            styles: {
              halign: "left",
            },
          },
          {
            content: (inv.stampDuty
              ? ex && ex.year < 2023
                ? 0.6
                : 1
              : 0
            ).toFixed(3),
            styles: {
              halign: "right",
            },
          },
        ],
        [
          {
            content: "",
            styles: {
              halign: "left",
            },
          },
          {
            content: "TOTAL",
            styles: {
              halign: "left",
            },
          },
          {
            content: format_number(
              inv.items.reduce((a, b) => a + b.amount * b.qty, 0) +
                (inv.stampDuty ? (ex && ex.year < 2023 ? 0.6 : 1) : 0)
            ),
            styles: {
              halign: "right",
            },
          },
        ],
      ],
      theme: "plain",
      tableWidth: "wrap",
      margin: { left: 12 },
    });
    autoTable(doc, {
      body: [
        [
          {
            content: agency.name,
            styles: {
              halign: "right",
              textColor: "#27509a",
              fontSize: 8,
              fontStyle: "bold",
              cellPadding: { right: 2 },
              overflow: "visible",
            },
          },
          // {
          //   content: "SARL",
          //   styles: {
          //     halign: "left",
          //     textColor: "#111",
          //     fontSize: 8,
          //     fontStyle: "italic",
          //     cellPadding: { top: 3 },
          //   },
          // },
        ],
      ],
      theme: "plain",
      styles: {
        lineWidth: [0, 0, 0, 0.3],
        lineColor: "#232323",
        fillColor: "#fff",
      },
      tableWidth: "wrap",
      startY: doc.internal.pageSize.getHeight() - 55,
    });
    autoTable(doc, {
      body: [
        [
          {
            content: agency.rib,
            styles: {
              halign: "left",
              textColor: "#323232",
              fontSize: 10,
            },
          },
        ],
        [
          {
            content: agency.swift,
            styles: {
              halign: "left",
              textColor: "#323232",
              fontSize: 10,
            },
          },
        ],
      ],
      theme: "plain",
      styles: {
        lineWidth: [0, 0, 0, 0.3],
        lineColor: "#232323",
        fillColor: "#fff",
      },

      tableWidth: doc.internal.pageSize.getWidth() - 30,
      margin: { top: 100 },
      tableLineColor: [155, 155, 155],
      tableLineWidth: 0.3,
      startY: doc.internal.pageSize.getHeight() - 50,
    });

    return doc.save("invoice" + inv.number);
  }
  const save = () => {
    console.log(invoiceModel);
    // let msg = validate(invoiceModel, [
    //   { agencyId: "société" },
    //   { date: "date" },
    //   { clientId: "Client" },
    // ]);
    // setstate((prev) => {
    //   return { ...prev, loading: true };
    // });
    // if (msg) seterror(msg);
    // else {
    let m = {
      ...invoiceModel,
      version3: invoiceModel.isAvoir ? false : true,
      items: invoiceModel.items.map((i) => {
        let _i = { ...i };
        if (typeof _i.id == "string") delete _i.id;
        return _i;
      }),
    };
    if (invoiceModel.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Invoice)
        .update(invoiceModel.id, m)
        .then((res) => {
          setopen(false);
          // setstate((prev) => {
          //   return { ...prev, open: false, loading: false };
          // });
          setinvoiceModel({
            items: [],
            clientId: 0,
            agencyId: 0,
            date: new Date(),
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          console.log(e.Message);
        });
    } else {
      APi.createAPIEndpoint(
        APi.ENDPOINTS.Invoice + (m.isAvoir ? "/avoir" : "/v3")
      )
        .create(m)
        .then((res) => {
          downloadInvoice(res.data);
          setopen(false);
          setinvoiceModel({
            items: [],
            clientId: 0,
            agencyId: 0,
            date: new Date(),
          });
          // setstate((prev) => {
          //   return { ...prev, loading: false, open: false };
          // });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          if (e.response.data.includes("Il y'a déja"))
            Swal.fire({
              position: "center",
              icon: "error",
              title: e.response.data,
              showConfirmButton: true,
              timer: 14500,
            });
        });
    }
  };
  useEffect(() => console.log(checkeds), [checkeds]);
  return (
    <div style={{ marginTop: "30px" }}>
      <div style={{ textAlign: "center", padding: "20px " }}>
        <h1 style={{ color: "#0079ad" }}> Génerer Factures </h1>
        <input
          id="hidden"
          style={{
            visibility: "hidden",
            width: "0",
            height: 0,
            overflow: "hidden",
          }}
          type="file"
          onChange={readfile2}
          accept=".xlsx,.xls"
        />
        <button
          onClick={() => {
            const el = document.querySelector("input#hidden");
            console.log(el);
            el.click();
          }}
          style={{
            padding: "15px 30px",
            background: "#00a98d",
            color: "#fff",
            fontSize: "15px",
            margin: "4px",
            border: "0 none ",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Choisir fichier{" "}
        </button>

        <h4 style={{ color: "#8888" }}>{sheetName}</h4>
      </div>

      <div
        style={{
          textAlign: "right",
        }}
      >
        <button
          onClick={() => {
            console.log(clientsFromDb);
            console.log(selectedclient);
            setopen(true);
            console.log(
              clientsFromDb &&
                clientsFromDb.find((c) => c.name == selectedclient)
            );
            setinvoiceModel((prev) => {
              let m = {
                ...prev,
                clientId: clientsFromDb.find((c) => c.name == selectedclient)
                  ? clientsFromDb.find((c) => c.name == selectedclient).id
                  : 0,
                isAvoir: active == 1 ? true : false,
              };
              m.items = filteredData.map((el, i) => ({ ...el, index: i + 1 }));
              return m;
            });
          }}
          style={{
            background: "#0079ad",
            color: "#fff",
            padding: "10px 20px",
          }}
        >
          {" "}
          Génerer {`${["Facture", "Avoir"][active]}`}
        </button>
      </div>
      <div>
        {" "}
        <button
          className={"__btn " + (!active ? "active" : "")}
          onClick={() => {
            setactive(0);
            setcheckeds([]);
            setfilteredData(data);
            setselectedclient(0);
          }}
        >
          Facture
        </button>{" "}
        <button
          className={"__btn " + (active == 1 ? "active" : "")}
          onClick={() => {
            setactive(1);
            setcheckeds([]);

            setfilteredData(dataRefund);
            setselectedclient(0);
          }}
        >
          Avoir
        </button>
      </div>
      <div style={{ maxWidth: "100%", overflow: "auto" }}>
        <table style={{ margin: "20px auto", border: "1px solid #eee" }}>
          {[data, dataRefund, dataAdm][active] &&
          [data, dataRefund, dataAdm][active].length ? (
            <thead>
              <tr style={{ background: "#2f1a4c" }}>
                <Checkbox
                  checked={
                    [data, dataRefund, dataAdm][active].length ==
                    checkeds.length
                  }
                  onChange={(v) =>
                    setcheckeds((prev) =>
                      prev.length
                        ? []
                        : [data, dataRefund, dataAdm][active].map((el) => el.id)
                    )
                  }
                />
                {Object.keys([data, dataRefund, dataAdm][active][0]).map(
                  (el, i) => (
                    <th
                      style={{
                        padding: "10px",
                        textTransform: "capitalize",
                        color: "#fff",
                        textAlign: "left",
                      }}
                    >
                      {i ? el : ""}
                      {/* {!i && (
                      <input
                        style={{ color: "#999" }}
                        onChange={(e) =>
                          setfilteredData(
                            data.filter((el) =>
                              el.client.includes(e.target.value.toUpperCase())
                            )
                          )
                        }
                      />
                    )} */}
                      {!i && (
                        <SelectPicker
                          data={[{ label: "Tout", value: 0 }].concat(clients)}
                          block
                          value={selectedclient}
                          onSelect={(v) => {
                            setselectedclient(v);
                            setfilteredData(
                              [data, dataRefund, dataAdm][active].filter((el) =>
                                el.client.includes(v.toUpperCase())
                              )
                            );
                          }}
                        />
                      )}
                    </th>
                  )
                )}
              </tr>
            </thead>
          ) : (
            ""
          )}
          {filteredData.map((x, i) => {
            return (
              <tr style={{ background: i % 2 ? "#eee" : "#fff" }}>
                <Checkbox
                  checked={checkeds.find((el) => el == x.id)}
                  onChange={() =>
                    setcheckeds((prev) =>
                      prev.find((el) => el == x.id)
                        ? prev.filter((el) => el != x.id)
                        : [...prev, x.id]
                    )
                  }
                />
                {Object.keys(x).map((el, j) => (
                  <td
                    style={{
                      padding: "10px",
                      textTransform: "capitalize",
                      color: j ? "#222" : "#00a98d",
                      fontWeight: j ? "normal" : "bold",
                      //   background: el == "status" && ,
                    }}
                  >
                    {x[el]}
                  </td>
                ))}
              </tr>
            );
          })}
        </table>
      </div>
      <Modal size="md" overflow={false} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Détails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEdit
            save={save}
            taxes={taxes}
            agencies={agencies}
            fetchClients={fetchClients}
            clients={clientsFromDb}
            error={""}
            model={invoiceModel}
            _setmodel={setinvoiceModel}
          />
        </Modal.Body>
      </Modal>
      {/*  */}
      <style jsx>{`
        .__btn {
          padding: 8px 12px;
          border: 1px solid #aaa;
          border-radius: 5px;
          background: #fff;
        }
        .active {
          background: rgb(84, 159, 10);
          color: #fff;
        }
      `}</style>
    </div>
  );
}
