import React from "react";
import { useRecoilState } from "recoil";
import { PreferenceAtom } from "../../Atoms/preference.atom";
import { SelectPicker } from "rsuite";
import { agencyAtoms } from "../../Atoms/agencies.atom";

export default function Preference() {
  const [pref, setpref] = useRecoilState(PreferenceAtom);
  const [agencies, setagencies] = useRecoilState(agencyAtoms);

  return (
    <div>
      <label>Société</label>
      <SelectPicker
        data={[{ label: "Tout", value: null }].concat(
          agencies.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={pref.agencyId}
        onSelect={(agencyId) => {
          setpref((prev) => {
            localStorage.setItem("pref", JSON.stringify({ ...prev, agencyId }));
            return { ...prev, agencyId };
          });
        }}
      />
      {/* <label>Exercice</label>
      <SelectPicker
        data={new Array(5).fill(new Date().getFullYear()).map((c, i) => {
          return { label: c - i, value: c - i };
        })}
        block
        noSearch
        value={pref.exercice}
        onSelect={(exercice) => {
          setpref((prev) => {
            localStorage.setItem("pref", JSON.stringify({ ...prev, exercice }));
            return { ...prev, exercice };
          });
        }}
      /> */}
    </div>
  );
}
