import GearIcon from "@rsuite/icons/Gear";
import PageNextIcon from "@rsuite/icons/PageNext";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import ShareOutlineIcon from "@rsuite/icons/ShareOutline";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Content,
  Dropdown,
  Header,
  Nav,
  Navbar,
  Sidebar,
  Sidenav,
} from "rsuite";

import { BsMenuButton } from "react-icons/bs";
import { Link, Route, Switch } from "react-router-dom";
import "./App.scss";

import PageIcon from "@rsuite/icons/Page";
import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import "../node_modules/react-vis/dist/style.css";
import { APi } from "./Api";
import { agencyAtoms } from "./Atoms/agencies.atom";
import { agencyAtom } from "./Atoms/agency.atom";
import { isLogged } from "./Atoms/auth.atom";
import { CurrencyAtom } from "./Atoms/currency.atom";
import { ExrciceAtom } from "./Atoms/exercice.atom";
import { PreferenceAtom } from "./Atoms/preference.atom";
import { TaxAtom } from "./Atoms/taxes.atom";
import Login from "./Screens/Auth/login";
import Check from "./Screens/Check";
import Exercices from "./Screens/Exercice";
import Invoice from "./Screens/Invoice";
import Payment from "./Screens/Payment";
import Client from "./Screens/Tiers/Client";
import Bank from "./Screens/Treasory/Bank";
import Checkout from "./Screens/Treasory/Checkout";
import Agencies from "./Screens/agencies";
import Preference from "./Screens/preference";
import Taxes from "./Screens/taxes";
import InvoiceV2 from "./Screens/InvoiceV2";
import InvoiceGenerator from "./Screens/invoiceGenerator";
import InvoiceV3 from "./Screens/InvoiceV3";
import AvoirTicketing from "./Screens/AvoirTicketing";

const iconStyles = {
  width: 56,
  height: 56,
  padding: 18,
  lineHeight: "56px",
  textAlign: "center",
};

const App = (props) => {
  const setexercices = useSetRecoilState(ExrciceAtom);

  const [expand, setExpand] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [active, setactive] = useState(1);
  const [logged, setlogged] = useRecoilState(isLogged);
  const [pref, setpref] = useRecoilState(PreferenceAtom);
  const setcurrencies = useSetRecoilState(CurrencyAtom);
  const setAgency = useSetRecoilState(agencyAtom);
  const [pageTitle, setpageTitle] = useState("Dashboard");
  const location = useLocation();
  const [agencies, setagencies] = useRecoilState(agencyAtoms);
  const [taxes, settaxes] = useRecoilState(TaxAtom);
  const fetchAgencies = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Agency + "/getAll", {})
      .fetchAll()
      .then((res) => {
        setagencies(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  const fetchTaxes = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Tax + "/getAll", {})
      .fetchAll()
      .then((res) => {
        settaxes(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  useEffect(() => {
    fetchAgencies();
    fetchTaxes();
    APi.createAPIEndpoint(APi.ENDPOINTS.Currency, { page: 1, take: 1000 })
      .fetchAll()
      .then((res) => setcurrencies(res.data.data));

    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    } else {
    }
    setlogged(log);
    console.log(log);
    setTimeout(() => {
      setloaded(true);
      setpageTitle(routes[location.pathname]);
    }, 1000);
  }, [logged]);
  useEffect(() => {
    setExpand(false);
  }, [pageTitle]);
  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Exercice, {})
      .fetchAll()
      .then((res) => {
        setexercices(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  useEffect(() => {
    fetch();
    let _pref = localStorage.getItem("pref");
    if (_pref) setpref(JSON.parse(_pref));
    let data = JSON.stringify({
      Name: "dzdzdzd",
      Email: "Passddder00$",
      Message: "test",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://script.google.com/macros/s/AKfycbxvtEEcOD5tE4pNJHc1zvtEC-PoDRX2kKPAl22iyHhDJ1fwZpBV0dRKwSB_m1MEbUl-VA/exec",
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
        //   'Cookie': 'NID=511=QfVPCi6TNSbI5yNeOVdD9fJIUkveggg7YO-7z96cbNZ3wpQFXUeN2vFmVvJEow0XygV89oXpoWVbLxkDcBe0x134Ofr_CLofZNgaolkQya8agb2sgfshT4ylsRu_j7FNVSeH_ZL9kwuc88FHsrMwDe1TiiFk4r4jff_4tZgz89M'
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {});
  }, []);
  const AuthGard = (Screen) => (logged ? Screen : <Login />);
  return (
    <div className="app">
      <Container>
        {logged && (
          <Sidebar className={"app-sidebar " + (expand ? "show" : "")}>
            <Sidenav.Header>
              <div className="app-sidebar-header">
                <div>
                  <b style={{ marginLeft: 12, fontSize: "large" }}> InVoicy</b>{" "}
                </div>{" "}
                <button
                  className="close_menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  x
                </button>
              </div>
            </Sidenav.Header>
            <Sidenav defaultOpenKeys={["1"]} appearance="subtle">
              <Sidenav.Body>
                <Nav>
                  <Nav.Item
                    icon={<PageIcon />}
                    eventKey="11"
                    onClick={() => {
                      setactive("11");
                      setpageTitle("Factures");
                    }}
                    active={active == "11"}
                  >
                    <Link className={"side_link "} to="/invoices">
                      Factures
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey=" V2"
                    onClick={() => {
                      setactive(" V2");
                      setpageTitle("Factures  V2");
                    }}
                    icon={<PageIcon />}
                    active={active == " V2"}
                  >
                    <Link className={"side_link "} to="/invoices2">
                      Factures V2
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey=" V3"
                    onClick={() => {
                      setactive(" V3");
                      setpageTitle("Factures  Billetrie");
                    }}
                    icon={<PageIcon />}
                    active={active == " V3"}
                  >
                    <Link className={"side_link "} to="/invoices3">
                      Factures Billètrie
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="Avoir Billètrie"
                    onClick={() => {
                      setactive("Avoir Billètrie");
                      setpageTitle("Avoir Billètrie");
                    }}
                    icon={<ChangeListIcon />}
                    active={active == "Avoir Billètrie"}
                  >
                    <Link className={"side_link "} to="/avoir_ticketing">
                      Avoir Billètrie
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="invoice_generator"
                    icon={<ShareOutlineIcon />}
                    onClick={() => {
                      setactive("invoice_generator");
                      setpageTitle("Generer Facture");
                    }}
                    active={active == "invoice_generator"}
                  >
                    <Link className={"side_link "} to="/invoice_generator">
                      Générer Facture
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="12"
                    onClick={() => {
                      setactive("12");
                      setpageTitle("Agences");
                    }}
                    icon={<ShareOutlineIcon />}
                    active={active == "12"}
                  >
                    <Link className={"side_link "} to="/agencies">
                      Agences
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="aa2"
                    onClick={() => {
                      setactive("aa2");
                      setpageTitle("Clients");
                    }}
                    icon={<PageIcon />}
                    active={active == "aa2"}
                  >
                    <Link className={"side_link "} to="/clients">
                      Clients
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="1aa1"
                    onClick={() => {
                      setactive("1aa1");
                      setpageTitle("Taxes");
                    }}
                    icon={<PageIcon />}
                    active={active == "1aa1"}
                  >
                    <Link className={"side_link "} to="/taxes">
                      Taxes
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    icon={<PageIcon />}
                    eventKey="1dzz1"
                    onClick={() => {
                      setactive("1dzz1");
                      setpageTitle("Exercices de comptabilité");
                    }}
                    active={active == "1dzz1"}
                  >
                    <Link className={"side_link "} to="/exercices">
                      Exercices de comptabilité
                    </Link>
                  </Nav.Item>
                  {/* <Nav.Item
                    eventKey="5"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("5");
                      setpageTitle(" Gestion des chéques");
                    }}
                    active={active == "5"}
                  >
                    <Link className={"side_link "} to="/checks">
                      Gestion des chèques
                    </Link>
                  </Nav.Item>
                  <hr></hr> */}
                  {/* <Nav.Item
                    eventKey="10"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("10");
                      setpageTitle(" Gestion des recouvrements");
                    }}
                    active={active == "10"}
                  >
                    <Link className={"side_link "} to="/payments">
                      Gestion des recouvrements
                    </Link>
                  </Nav.Item>
                  <Dropdown
                    eventKey="6"
                    trigger="hover"
                    title="Trésorerie"
                    icon={
                      <span className="side-span">
                        <ProjectIcon />
                      </span>
                    }
                    placement="rightStart"
                  >
                    <Dropdown.Item
                      eventKey="6-1"
                      onClick={() => {
                        setactive("6-1");
                        setpageTitle("Banques");
                      }}
                      active={active == "6-1"}
                    >
                      <Link className={"side_link "} to="/treasory/banks">
                        Banques
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="6-2"
                      onClick={() => {
                        setactive("6-2");
                        setpageTitle(" Caisses");
                      }}
                      active={active == "6-2"}
                    >
                      <Link className={"side_link "} to="/treasory/checkouts">
                        Caisses
                      </Link>
                    </Dropdown.Item>
                  </Dropdown> */}
                  <Nav.Item
                    icon={<GearIcon />}
                    eventKey="1zz1"
                    onClick={() => {
                      setactive("1zz1");
                      setpageTitle("Préférences");
                    }}
                    active={active == "1zz1"}
                  >
                    <Link className={"side_link "} to="/preferences">
                      Préférences
                    </Link>
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
          </Sidebar>
        )}

        <Container className={"hole-container"}>
          {logged && (
            <Header className="page-header">
              <h4>{pageTitle}</h4>

              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <button
                  className="menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  <BsMenuButton />
                </button>
                <Nav>
                  <Dropdown
                    placement="bottomEnd"
                    trigger="click"
                    icon={<GearIcon size="3em" />}
                    renderTitle={(children) => {
                      return <GearIcon style={iconStyles} />;
                    }}
                  >
                    {/* <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("auth");
                        setlogged(false);
                      }}
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </div>
            </Header>
          )}

          <Content className={"app-content " + (!logged ? "" : "logged")}>
            <Switch>
              <Route
                path="/invoices"
                render={(props) => AuthGard(<Invoice {...props} />)}
              />
              <Route
                path="/invoices2"
                render={(props) => AuthGard(<InvoiceV2 {...props} />)}
              />
              <Route
                path="/invoices3"
                render={(props) => AuthGard(<InvoiceV3 {...props} />)}
              />
              <Route
                path="/avoir_ticketing"
                render={(props) => AuthGard(<AvoirTicketing {...props} />)}
              />
              <Route
                path="/agencies"
                render={(props) => AuthGard(<Agencies {...props} />)}
              />
              <Route
                path="/invoice_generator"
                render={(props) => AuthGard(<InvoiceGenerator {...props} />)}
              />
              <Route
                path="/exercices"
                render={(props) => AuthGard(<Exercices {...props} />)}
              />
              <Route
                path="/clients"
                render={(props) => AuthGard(<Client {...props} />)}
              />
              <Route
                path="/taxes"
                render={(props) => AuthGard(<Taxes {...props} />)}
              />
              <Route
                path="/preferences"
                render={(props) => AuthGard(<Preference {...props} />)}
              />
              <Route
                path="/payments"
                render={(props) => AuthGard(<Payment {...props} />)}
              />
              <Route
                path="/checks"
                render={(props) => AuthGard(<Check {...props} />)}
              />
              <Route
                path="/treasory/banks"
                render={(props) => AuthGard(<Bank {...props} />)}
              />
              <Route
                path="/treasory/checkouts"
                render={(props) => AuthGard(<Checkout {...props} />)}
              />{" "}
              <Route
                path="/*"
                render={(props) => AuthGard(<Invoice {...props} />)}
              />
            </Switch>
          </Content>
        </Container>
      </Container>
      <style jsx>{`
        .side-span {
          padding: 10px;
        }
      `}</style>
    </div>
  );
};
const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? <PagePreviousIcon /> : <PageNextIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};
export default App;

const routes = {
  "/sales": "Ventes",
  "/expense": "Dépenses",
  "/tiers/creditors": "Créditeurs",
  "/tiers/providers": "Fournisseurs",
  "/tiers/clients": "Clients",
  "/checks": " Gestion des chéques",
  "/settings/employee_position": " Postes des employés",
  "/settings/services": " Types Des Services",
  "/settings/currencies": "Devises",
  "/treasory/banks": "Banques",
  "/treasory/checkouts": "Caisses",
  "/invoices": "Factures",
  "/invoices/settings": "Paramétrage Facture",
};
