import { useRecoilValue } from "recoil";
import { Input, Message, SelectPicker } from "rsuite";
import { agencyAtoms } from "../../Atoms/agencies.atom";

function AddEdit({ _setmodel, error, model }) {
  const agencies = useRecoilValue(agencyAtoms);

  return (
    <>
      <label>Année :</label>
      <Input
        type="number"
        step="1"
        onChange={(year) => {
          _setmodel((prev) => {
            return { ...prev, year };
          });
        }}
        value={model.year}
      />
      <label>Numero de début Facture :</label>
      <Input
        onChange={(last) => {
          _setmodel((prev) => {
            return { ...prev, last };
          });
        }}
        value={model.last}
      />
      <label>Numero de début Avoir:</label>
      <Input
        onChange={(lastAvoir) => {
          _setmodel((prev) => {
            return { ...prev, lastAvoir };
          });
        }}
        value={model.lastAvoir}
      />
      <label>Société</label>
      <SelectPicker
        data={[{ label: "Tout", value: 0 }].concat(
          agencies.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.agencyId}
        onSelect={(agencyId) => {
          _setmodel((prev) => {
            return { ...prev, agencyId };
          });
        }}
      />
      {/* <label>Services </label>
      <TagPicker
        data={serviceTypes}
        block
        onSelect={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, serviceTypes: s.join() };
          });
        }}
        onChange={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, serviceTypes: s.join() };
          });
        }}
        value={
          model.serviceTypes
            ? model.serviceTypes.split(",").map((el) => parseInt(el))
            : null
        }
      /> */}
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
