const numbersToWords = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "forty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety",
};

// Define the convertNumberToWords function
function convertNumberToWords(number) {
  // if number present in object no need to go further
  if (number in numbersToWords) return numbersToWords[number];

  // Initialize the words variable to an empty string
  let words = "";

  // If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
  if (number >= 100) {
    // Add the word form of the number of hundreds to the words string
    words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

    // Remove the hundreds place from the number
    number %= 100;
  }

  // If the number is greater than zero, handle the remaining digits
  if (number > 0) {
    // If the words string is not empty, add "and"
    if (words !== "") words += " and ";

    // If the number is less than 20, look up the word form in the numbersToWords object
    if (number < 20) words += numbersToWords[number];
    else {
      // Otherwise, add the word form of the tens place to the words string
      //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
      words += numbersToWords[Math.floor(number / 10) * 10];

      // If the ones place is not zero, add the word form of the ones place
      if (number % 10 > 0) {
        words += "-" + numbersToWords[number % 10];
      }
    }
  }

  // Return the word form of the number
  return words;
}

console.log(convertNumberToWords(123));
const numbersToWordsFR = {
  0: "zero",
  1: "un",
  2: "deux",
  3: "trois",
  4: "quatre",
  5: "cinq",
  6: "six",
  7: "sept",
  8: "huit",
  9: "neuf",
  10: "dix",
  11: "onze",
  12: "douze",
  13: "treize",
  14: "quatorze",
  15: "quinze",
  16: "seize",
  17: "dix-sept",
  18: "dix-huit",
  19: "dix-neuf",
  20: "vingt",
  30: "trente",
  40: "quarante",
  50: "cinquante",
  60: "soixante",
  70: "soixante-dix",
  71: "soixante et onze",
  72: "soixante-douze",
  73: "soixante-treize",
  74: "soixante-quatorze",
  75: "soixante-quinze",
  76: "soixante-seize",
  77: "soixante-dix-sept",
  78: "soixante-dix-huit",
  79: "soixante-dix-neuf",
  80: "quatre-vingts",
  81: "quatre-vingt-un",
  82: "quatre-vingt-deux",
  83: "quatre-vingt-trois",
  84: "quatre-vingt-quatre",
  85: "quatre-vingt-cinq",
  86: "quatre-vingt-six",
  87: "quatre-vingt-sept",
  88: "quatre-vingt-huit",
  89: "quatre-vingt-neuf",
  90: "quatre-vingt-dix",
  91: "quatre-vingt-onze",
  92: "quatre-vingt-douze",
  93: "quatre-vingt-treize",
  94: "quatre-vingt-quatorze",
  95: "quatre-vingt-quinze",
  96: "quatre-vingt-seize",
  97: "quatre-vingt-dix-sept",
  98: "quatre-vingt-dix-huit",
  99: "quatre-vingt-dix-neuf",
};

// Define the convertNumberToWords function
function convertNumberToWordsFR(number) {
  // if number present in object no need to go further
  if (number in numbersToWordsFR) return numbersToWordsFR[number];

  // Initialize the words variable to an empty string
  let words = "";
  if (number >= 1000000) {
    // Add the word form of the number of hundreds to the words string
    words +=
      convertNumberToWordsFR(Math.floor(number / 1000000)) +
      " million" +
      (number / 1000000 >= 2 ? "s" : "");

    // Remove the hundreds place from the number
    number %= 1000000;
  }
  if (number >= 1000) {
    if (words !== "" && number) words += " ";

    // Add the word form of the number of hundreds to the words string
    words +=
      convertNumberToWordsFR(Math.floor(number / 1000)) +
      " mille" +
      (number / 1000 >= 2 ? "s" : "");

    // Remove the hundreds place from the number
    number %= 1000;
  }

  if (number >= 100) {
    // Add the word form of the number of hundreds to the words string
    if (words !== "" && number) words += " ";

    words +=
      convertNumberToWordsFR(Math.floor(number / 100)) +
      " cent" +
      (number / 100 >= 2 ? "s" : "");

    // Remove the hundreds place from the number
    number %= 100;
  }

  // If the number is greater than zero, handle the remaining digits
  if (number > 0) {
    // If the words string is not empty, add "and"
    if (words !== "") words += " ";
    // If the number is less than 20, look up the word form in the numbersToWords object
    if (number <= 20 || number >= 70) words += numbersToWordsFR[number];
    else if (number > 20 && number < 70) {
      // Otherwise, add the word form of the tens place to the words string
      //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
      words += numbersToWordsFR[Math.floor(number / 10) * 10];

      // If the ones place is not zero, add the word form of the ones place
      if (number % 10 > 0) {
        words += "-" + numbersToWordsFR[number % 10];
      }
    }
  }

  // Return the word form of the number
  words = words.replace("un ", "");
  words = words.replace(" un ", "");
  return words;
}

function Final_Amount_Converter(
  n,
  currency = "dinars",
  subbcurrency = "millimes"
) {
  console.log(n);
  var f = "";
  var decimal = 0;
  if (n - Math.floor(n) > 0) decimal = Math.floor((n * 1000) % 1000);
  console.log(decimal);
  f = convertNumberToWordsFR(Math.floor(n)) + " " + currency;
  if (decimal)
    f += " et " + convertNumberToWordsFR(decimal) + " " + subbcurrency;
  return f;
}
export default Final_Amount_Converter;
