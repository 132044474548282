export const ENDPOINTS = {

  Invoice: "Invoice",
  Agency: "Agency",
  Auth: "Auth",

  Invoice: "Invoice",
  Role: "Role",

    Accounts: "Accounts",
    Attachment: "Attachment",
    Bank: "Bank",
    Check: "Check",
    Checkout: "Checkout",
    Client: "Client",
    Creditor: "Creditor",
    Currency: "Currency",
    Expense: "Expense",
    Package: "Package",
    PackageItem: "PackageItem",
    Provider: "Provider",
    Sale: "Sale",
    Order: "Order",
    ServiceType: "ServiceType",
    Tag: "Tag",
    Ticket: "Ticket",
    Employee: "Employee",
    Payment: "Payment",
    EmployeePosition: "EmployeePosition",
    AgentBookingFlight: "AgentBookingFlight",
    File: "file",
    Purchase: "Purchase",
    Ledger: "Ledger",
    Exchange: "Exchange",
    RecoveryPoint: "RecoveryPoint",
    PackageUmrah: "PackageUmrah",
    Airline: "Airline",
    Exercice: "Exercice", 
    Transaction: "Transaction",
    Tax: "Tax",
 

};
