import { Input, Message, TagPicker } from "rsuite";
import { serviceTypes } from "../../Constants/types";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />
      <label>Valeur</label>
      <Input
        value={model.rate}
        onChange={(rate) => {
          _setmodel((prev) => {
            return { ...prev, rate: parseInt(rate) };
          });
        }}
      />
      {/* <label>Services </label>
      <TagPicker
        data={serviceTypes}
        block
        onSelect={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, serviceTypes: s.join() };
          });
        }}
        onChange={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, serviceTypes: s.join() };
          });
        }}
        value={
          model.serviceTypes
            ? model.serviceTypes.split(",").map((el) => parseInt(el))
            : null
        }
      /> */}
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
