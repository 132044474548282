import VisibleIcon from "@rsuite/icons/Visible";
import moment from "moment";
import React, { useEffect, useState } from "react";

import autoTable from "jspdf-autotable";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Input, Modal, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import { agencyAtoms } from "../../Atoms/agencies.atom";
import { ExrciceAtom } from "../../Atoms/exercice.atom";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { PreferenceAtom } from "../../Atoms/preference.atom";
import { TaxAtom } from "../../Atoms/taxes.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import Final_Amount_Converter from "../../Helpers/amountToString";
import format_number from "../../Helpers/number_formatter";
import validate from "../../Helpers/validate";
import jsPDF from "../../Janna LT Bold-normal";
import AddEdit from "./AddEdit.component";
import ResumeCard from "../../Components/ResumeCard";
import { IoChevronForwardCircleOutline } from "react-icons/io5";

export default function AvoirTicketing(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [error, seterror] = useState("");
  const [totalCount, settotalCount] = useState(0);
  const [totalTTC, settotalTTC] = useState(0);
  const [totalHT, settotalHT] = useState(0);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [agencies, setagencies] = useRecoilState(agencyAtoms);
  const [taxes, settaxes] = useRecoilState(TaxAtom);
  const [pref, setpref] = useRecoilState(PreferenceAtom);
  const [state, setstate] = useRecoilState(exportAddAtom);

  const fetchAgencies = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Agency + "/getAll", {})
      .fetchAll()
      .then((res) => {
        setagencies(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };

  const [filterModel, setfilterModel] = useState({
    q: "",
    clientId: 0,
    agencyId: 0,
    page: 1,
    take: 20,
    exerciceId: 0,
  });
  const [invoiceModel, setinvoiceModel] = useState({
    items: [],
    clientId: 0,
    agencyId: 0,
    date: new Date(),
    stampDuty: true,
  });
  const exercices = useRecoilValue(ExrciceAtom);

  // --- add edit model ---

  // ATOMS
  // HELPERS

  // API CALLS

  const fetch = (page = null) => {
    APi.createAPIEndpoint(
      APi.ENDPOINTS.Invoice + "/avoir",
      {
        ...filterModel,
        page: page ? page : filterModel.page,
      },
      ""
    )
      .customGet()
      .then((res) => {
        setdata(res.data.data);
        settotalCount(res.data.totalCount);
        settotalTTC(res.data.totalTTC);
        settotalHT(res.data.totalHT);
      })
      .catch((e) => console.log(e.Message));
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Invoice)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => console.log(e.Message));
  };

  const columns = [
    {
      value: "number",

      name: "Réference",
      render: (v, v2) => <h6>{("0000" + v).split("").slice(-4)} </h6>,
    },
    {
      value: "client",
      name: "Client",
      render: (v) => <a>{v ? v.name.toUpperCase() : "-"}</a>,
    },

    {
      value: "date",
      name: "Date",
      render: (v) => (
        <h6 style={{ fontSize: "12px" }}>{moment(v).format("DD-MM-YYYY")}</h6>
      ),
    },
    {
      value: "ttc",
      name: "Montant TTC",
      render: (ttc) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {format_number(ttc)}
        </b>
      ),
    },
    {
      value: "commission",
      name: "Commission",
      render: (commission, version2) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {format_number(commission)}
        </b>
      ),
    },

    {
      name: "détails",
      value: "id",
      render: (v) => (
        <button
          onClick={(e) => {
            downloadInvoice(data.find((el) => el.id == v));
          }}
          style={{
            color: "rgba(67,55,160,1)",
            padding: "6px 10px",
            fontSize: "12px",
            background: "rgba(67,55,160,0.1)",
            borderRadius: "4px",
          }}
        >
          <VisibleIcon /> {"  "}
        </button>
      ),
    },
  ];
  async function downloadInvoice(inv) {
    const doc = new jsPDF();
    let agency = agencies.find((el) => el.id == inv.agencyId);
    let ex = exercices.find((ex) => ex.id == inv.exerciceId);
    var imgData = agency.logo;
    console.log(agency);

    let c = await APi.createAPIEndpoint(ENDPOINTS.Client).fetchById(
      inv.clientId
    );
    let client = c.data;
    console.log(client);
    let numb = inv.number;
    console.log(client);
    doc.addImage(imgData, "JPEG", 15, 26, 36, 25);
    // .then((res) => (client = res.data));
    autoTable(doc, {
      body: [
        [
          {
            content: "",
            styles: {
              halign: "left",
              fontSize: 14,
              textColor: "#232323",
            },
          },
          {
            content: "Facture",
            styles: {
              halign: "right",
              fontSize: 14,
              textColor: "#232323",
              fontWeight: "bold",
            },
          },
        ],
      ],
      theme: "plain",
      styles: {
        fillColor: "#eee",
      },
    });

    autoTable(doc, {
      body: [
        [
          {
            content:
              "\n" +
              agency.name +
              "\n" +
              "\n" +
              agency.address +
              (agency.emails ? "\n" + agency.emails : "") +
              (agency.phones
                ? agency.phones.split(",").map((p) => "\nTél: " + p)
                : "") +
              "\nMF : " +
              agency.taxCode,
            styles: {
              halign: "left",
              fontSize: 10,
              textColor: "#27509a",
              //  fontStyle: "italic",
              fontWeight: "bold",
              fontStyle: "Amiri",
              cellPadding: { top: 12, left: 2, right: 4 },
            },
          },

          {
            content:
              "Client : \n" +
              client.name +
              "\n" +
              "\n" +
              client.address +
              "\n MF. / CIN : " +
              client.taxCode,
            styles: {
              halign: "left",
              fontSize: 10,
              textColor: "#27509a",
              fontWeight: "bold",
              fontStyle: "Amiri",
              cellPadding: { top: 5, left: 2 },
              lineColor: "#eeeeee",
              lineWidth: 0.3,
              overflow: "linebreak",
            },
          },
        ],
      ],
      theme: "plain",
      margin: { top: 5 },
      columnStyles: {
        0: {
          columnWidth: doc.internal.pageSize.getWidth() / 2 - 20,
        },
        1: {
          columnWidth: doc.internal.pageSize.getWidth() / 2 - 10,
        },
      },
    });
    autoTable(doc, {
      body: [
        [
          {
            content:
              "Date: " +
              moment(inv.date).format("DD-MM-YYYY") +
              "\nN. Facture: " +
              (ex ? ex.year : "") +
              " / " +
              ("0000" + numb).split("").slice(-4).join(""),
            styles: {
              halign: "left",
              fontSize: 10,
              textColor: "#232323",
              fontStyle: "bold",
              cellPadding: { left: 10 },
            },
          },
        ],
      ],
      tableWidth: doc.internal.pageSize.getWidth() - 30,
      theme: "plain",
    });

    autoTable(doc, {
      head: [
        [
          "Désignation",
          {
            content: "Qté",
            styles: {
              halign: "right",
            },
          },

          {
            content: "Prix Unitaire TTC",
            styles: {
              halign: "right",
            },
          },
          {
            content: "Prix Total TTC",
            styles: {
              halign: "right",
            },
          },
        ],
      ],
      foot: [
        [
          "Montant Total TTC",
          "",
          "",
          {
            content: format_number(
              inv.items.reduce((a, b) => {
                return a + b.amount * b.qty;
              }, 0)
            ),
            styles: {
              halign: "right",
            },
          },
        ],
      ],
      body: inv.items.map((a) => {
        return [
          a.designation.split("#")[0],
          // el.unitPrice,
          {
            content: a.qty,
            styles: {
              halign: "right",
            },
          },
          {
            content: format_number(a.amount),
            styles: {
              halign: "right",
            },
          },
          {
            content: format_number(a.qty * a.amount),
            styles: {
              halign: "right",
            },
          },
        ];
      }, []),
      theme: "striped",
      headStyles: {
        textColor: "#eee",
        fillColor: "#343a40",
      },
      footStyles: {
        fillColor: "#eee",
        textColor: "#343a40",
      },
    });

    autoTable(doc, {
      body: [
        [
          {
            content: "Arrêtée la présente facture à la somme de :",
            styles: {
              halign: "left",
              textColor: "#27509a",
              cellPadding: { left: 4 },
              fontStyle: "italic",
            },
          },
          {
            content: "Montant Total HT:",
            styles: {
              halign: "left",
            },
          },
          {
            content: format_number(
              inv.items.reduce((a, b) => a + b.amount * b.qty - b.commission, 0)
            ),
            styles: {
              halign: "right",
            },
          },
        ],

        [
          {
            content: Final_Amount_Converter(
              inv.items.reduce((a, b) => a + b.amount * b.qty, 0) +
                (inv.stampDuty ? (ex && ex.year < 2023 ? 0.6 : 1) : 0)
            ),
            styles: {
              halign: "left",
              fontWeight: "bold",
              fontStyle: "italic",
              cellPadding: { right: 7, left: 4 },
              overflow: "linebreak",
              cellWidth: doc.internal.pageSize.getWidth() / 2 + 10,
            },
          },
          {
            content: "Base TVA",
            styles: {
              halign: "left",
            },
          },
          {
            content: format_number(
              inv.items.reduce(
                (a, b) =>
                  a +
                  b.commission *
                    (1 -
                      (taxes.find((el) => el.id == inv.taxId)
                        ? taxes.find((el) => el.id == inv.taxId).rate / 100
                        : 0.19)),
                0
              )
            ),
            styles: {
              halign: "right",
            },
          },
        ],
        [
          {
            content: "",
            styles: {
              halign: "left",
            },
          },

          {
            content: "TVA",
            styles: {
              halign: "left",
            },
          },
          {
            content:
              (taxes.find((el) => el.id == inv.taxId)
                ? taxes.find((el) => el.id == inv.taxId).rate
                : "19") +
              "%              " +
              format_number(
                inv.items.reduce(
                  (a, b) =>
                    a +
                    b.commission *
                      (taxes.find((el) => el.id == inv.taxId)
                        ? taxes.find((el) => el.id == inv.taxId).rate / 100
                        : 0.19),
                  0
                )
              ),
            styles: {
              halign: "right",
            },
          },
        ],
        [
          {
            content: "",
            styles: {
              halign: "left",
            },
          },
          {
            content: "Droit de Timbre",
            styles: {
              halign: "left",
            },
          },
          {
            content: (inv.stampDuty
              ? ex && ex.year < 2023
                ? 0.6
                : 1
              : 0
            ).toFixed(3),
            styles: {
              halign: "right",
            },
          },
        ],
        [
          {
            content: "",
            styles: {
              halign: "left",
            },
          },
          {
            content: "TOTAL",
            styles: {
              halign: "left",
            },
          },
          {
            content: format_number(
              inv.items.reduce((a, b) => a + b.amount * b.qty, 0) +
                (inv.stampDuty ? (ex && ex.year < 2023 ? 0.6 : 1) : 0)
            ),
            styles: {
              halign: "right",
            },
          },
        ],
      ],
      theme: "plain",
      tableWidth: "wrap",
      margin: { left: 12 },
    });
    autoTable(doc, {
      body: [
        [
          {
            content: agency.name,
            styles: {
              halign: "right",
              textColor: "#27509a",
              fontSize: 8,
              fontStyle: "bold",
              cellPadding: { right: 2 },
              overflow: "visible",
            },
          },
          // {
          //   content: "SARL",
          //   styles: {
          //     halign: "left",
          //     textColor: "#111",
          //     fontSize: 8,
          //     fontStyle: "italic",
          //     cellPadding: { top: 3 },
          //   },
          // },
        ],
      ],
      theme: "plain",
      styles: {
        lineWidth: [0, 0, 0, 0.3],
        lineColor: "#232323",
        fillColor: "#fff",
      },
      tableWidth: "wrap",
      startY: doc.internal.pageSize.getHeight() - 55,
    });
    autoTable(doc, {
      body: [
        [
          {
            content: agency.rib,
            styles: {
              halign: "left",
              textColor: "#323232",
              fontSize: 10,
            },
          },
        ],
        [
          {
            content: agency.swift,
            styles: {
              halign: "left",
              textColor: "#323232",
              fontSize: 10,
            },
          },
        ],
      ],
      theme: "plain",
      styles: {
        lineWidth: [0, 0, 0, 0.3],
        lineColor: "#232323",
        fillColor: "#fff",
      },

      tableWidth: doc.internal.pageSize.getWidth() - 30,
      margin: { top: 100 },
      tableLineColor: [155, 155, 155],
      tableLineWidth: 0.3,
      startY: doc.internal.pageSize.getHeight() - 50,
    });

    return doc.save("invoice" + inv.number);
  }
  const save = () => {
    console.log(invoiceModel);
    let msg = validate(invoiceModel, [
      { agencyId: "société" },
      { date: "date" },
      { clientId: "Client" },
    ]);
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    if (msg) seterror(msg);
    else {
      let m = { ...invoiceModel };
      if (invoiceModel.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Invoice)
          .update(invoiceModel.id, invoiceModel)
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            setinvoiceModel({
              items: [],
              clientId: 0,
              agencyId: 0,
              date: new Date(),
            });
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            seterror(e.Message);
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Invoice + "/v3")
          .create({ ...invoiceModel, version3: true })
          .then((res) => {
            downloadInvoice(res.data);
            fetch();
            setinvoiceModel({
              items: [],
              clientId: 0,
              agencyId: 0,
              date: new Date(),
            });
            setstate((prev) => {
              return { ...prev, loading: false, open: false };
            });
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            seterror(e.Message);
          });
      }
    }
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    fetchClients();
  }, [filterModel.page, filterModel.take]);
  useEffect(() => {
    setinvoiceModel({
      items: [],
      clientId: 0,
      date: new Date(),
      agencyId: pref.agencyId,
      ttc: 0,
      commission: 0,
      designation: "",
      version2: true,
    });
  }, [pref.agencyId]);
  return (
    <div>
      <Filter search={() => fetch(1)}>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Recherche: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>

        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Société: </label>
          <SelectPicker
            noSearch
            data={[{ label: "Tout", value: 0 }].concat(
              agencies.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            value={filterModel.agencyId}
            onSelect={(agencyId) => {
              setfilterModel((prev) => {
                return { ...prev, agencyId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Exercice: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              exercices.map((c) => {
                return {
                  label: (
                    <b>
                      {c.year}
                      <i
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#444",
                        }}
                      >
                        {agencies.find((a) => a.id == c.agencyId) &&
                          agencies.find((a) => a.id == c.agencyId).name}
                      </i>
                    </b>
                  ),
                  value: c.id,
                };
              })
            )}
            block
            noSearch
            value={filterModel.exerciceId}
            onSelect={(exerciceId) =>
              setfilterModel((prev) => {
                return { ...prev, exerciceId };
              })
            }
          />
        </Responsive>
      </Filter>
      <ExportAdd
        ActionOnClose={() =>
          setinvoiceModel({
            items: [],
            clientId: 0,
            agencyId: 0,
          })
        }
        noExport
        size="md"
        save={save}
        AddComponent={
          <AddEdit
            taxes={taxes}
            agencies={agencies}
            fetchClients={fetchClients}
            clients={clients}
            error={""}
            model={invoiceModel}
            _setmodel={setinvoiceModel}
          />
        }
      />

      <div>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard
            icon={<IoChevronForwardCircleOutline />}
            notAmount
            text="Total Fatcures"
            color="245,195,35"
            amount={totalCount}
          />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total TTC" color="0,169,141" amount={totalTTC} />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total HT" amount={totalHT} color="229,57,53" />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total TVA" amount={totalTTC - totalHT} />
        </Responsive>
      </div>

      <Grid
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
        editAction={(id) => {
          let el = data.find((el) => el.id == id);
          if (el.version2) {
            el.items = el.items.map((it, i) => ({ ...it, index: i + 1 }));
            setinvoiceModel(el);

            setstate((prev) => {
              return { ...prev, open: true };
            });
          }
        }}
        deleteAction={deleteAction}
        actionKey="id"
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
      {/* <AddPayment
        fetch={fetch}
        open={selectedInvoice}
        handleClose={() => setselectedInvoice(0)}
        paidAmount={
           data && data.find((el) => el.id == selectedInvoice)
            ? data.find((el) => el.id == selectedInvoice).paidAmount
            : 0
        }
      /> */}
    </div>
  );
}
const AddPayment = ({ size, open, handleClose, fetch, paidAmount }) => {
  const [m, setm] = useState({
    numero_quittance: "",
    amount: 0,
  });
  useEffect(() => {
    setm((p) => ({
      numero_quittance: "",
      amount: 0,
    }));
  }, [open]);
  const save = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Invoice + "/SetPaid/" + open)
      .update2(m)
      .then((res) => {
        handleClose();
        fetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Modal
      size={size ? size : "lg"}
      overflow={false}
      open={open}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Paiement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Montant :</label>
          <Input
            type="number"
            step="0.1"
            value={m.amount}
            onChange={(amount) => {
              if (amount <= paidAmount) {
                setm((prev) => {
                  return {
                    ...prev,
                    amount: parseFloat(amount),
                  };
                });
              }
            }}
          />
        </Responsive>

        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>numero de quittance :</label>
          <Input
            value={m.numero_quittance}
            onChange={(numero_quittance) => {
              setm((prev) => {
                return {
                  ...prev,
                  numero_quittance,
                };
              });
            }}
          />
        </Responsive>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={save} appearance="primary">
          payer
        </Button>{" "}
        <Button onClick={handleClose} appearance="subtle">
          fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
